import { useEffect, useMemo, useState } from "react";
import { Outlet } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Box, Container } from "@mui/system";
import { useAuth } from "../hooks/useAuth";
import { Avatar, Grid, Paper, Stack, SvgIcon, Typography, useTheme } from "@mui/material";
import CampaignIcon from '@mui/icons-material/Campaign';
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import positionAPI from "../services/positionAPI";

export default function Dashboard() {
    const { user } = useAuth();
    const theme = useTheme();
    const [overallPositions, setOverallPositions] = useState([]);

    const fetchOverallPositions = async () => {
        const data = await positionAPI.fetchPositionsOverall();
        console.log(data);
        setOverallPositions(data);
    };

    useEffect(() => {
        fetchOverallPositions();
    }, []);

    // Calculate total value across all positions
    const totalValue = overallPositions ? overallPositions.reduce((acc, row) => acc + row.TotalValueEUR, 0) : 0;

    const formatCurrency = (value, currency, maximumFractionDigits) => new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: maximumFractionDigits
    }).format(value);

    return (
        <>
            <Helmet>
                <title>Dashboard</title>
            </Helmet>

            <Container maxWidth="xl">
                <Stack spacing={2} direction="column" sx={{ justifyContent: "center", alignItems: "center" }}>
                    <Avatar
                        sx={{
                            height: 70,
                            width: 70,
                        }}
                        src={user.Picture}
                    />
                    <Typography variant="h5">
                        Hello {user.FirstName}
                    </Typography>

                    <Grid container spacing={4} sx={{ mb: 4 }}>
                        {/* Networth */}
                        <Grid item xs={12} sm={12}>
                            <Box
                                sx={{
                                    height: 150,
                                    backgroundColor: '#f0f0f0',
                                    borderRadius: 2,
                                    boxShadow: 3,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    flexDirection: 'column',
                                    p: 2,
                                }}
                            >
                                <Typography variant="h6" color="textSecondary" sx={{ mb: 1 }}>
                                    Overall Networth
                                </Typography>
                                <Typography variant="h4" color="primary">
                                    {formatCurrency(totalValue, 'EUR', 0)}
                                </Typography>
                            </Box>
                        </Grid>

                    </Grid>

                </Stack>
            </Container>
        </>
    );
}