import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import {
    createBrowserRouter,
    createRoutesFromElements,
    Navigate,
    Route,
    RouterProvider,
    useLocation,
} from "react-router-dom";

import { AuthProvider } from './hooks/useAuth';
import { AuthLayout } from './layouts/authLayout';
import Callback from './routes/callback';
import Home from './routes/dashboard';
import Login from './routes/login';
import { ProtectedLayout } from './layouts/protectedLayout';
import { createTheme } from './theme/theme';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, IconButton, SvgIcon } from '@mui/material';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import { SnackbarProvider, closeSnackbar } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';
import Sleep from './routes/sleep';
import Settings from './routes/settings';
import Dashboard from './routes/dashboard';
import Inputs from './routes/inputs';

const theme = createTheme();

const router = createBrowserRouter(
    createRoutesFromElements(
        <>

            <Route path="/auth/login" element={<Login />} />
            <Route path="/auth/callback" element={<Callback />} />

            <Route element={<AuthLayout />}>
                <Route path="/" element={<ProtectedLayout />}>
                    <Route path="/" element={<Dashboard />} />
                    <Route path="/rocco" element={<Inputs />} />
                    <Route path="/bri" element={<Inputs />} />
                    <Route path="/liz" element={<Inputs />} />
                    <Route path="/settings" element={<Settings />} />
                    <Route path='*' element={<Navigate to='/' />} />
                </Route>
            </Route>
            <Route path='*' element={<Navigate to='/auth/login' />} />
        </>
    )
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <AuthProvider>
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <SnackbarProvider
                action={(snackbarId) => (
                    <IconButton aria-label="close" onClick={() => closeSnackbar(snackbarId)}>
                        <CloseIcon />
                    </IconButton>
                )}
                maxSnack={3}
            >
                <RouterProvider router={router} />
            </SnackbarProvider>
        </ThemeProvider>
    </AuthProvider>
);
