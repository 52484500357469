const fetchInvestments = async (user, page, rowsPerPage) => {
    let response;
    try {
        response = await fetch(process.env.REACT_APP_BACKEND_URL+'/api/investments/'+user+'?page='+page+'&pageSize='+rowsPerPage, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        });
    } catch (error) {
        // Handle network errors or errors during parsing
        console.error(error.message);
    }

    if (response.status === 403) {
        // Redirect to the login page
        window.location.href = '/auth/login';
    }

    if (!response.ok) {
        // Handle error status
        const errorData = await response.json();
        throw new Error(errorData.message || 'Unknown error');
    }

    // If response is OK, proceed with the data
    return await response.json()
};

const fetchAllInvestmentUsers = async () => {
    let response;
    try {
        response = await fetch(process.env.REACT_APP_BACKEND_URL+'/api/investments/users', {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        });
    } catch (error) {
        // Handle network errors or errors during parsing
        console.error(error.message);
    }

    if (response.status === 403) {
        // Redirect to the login page
        window.location.href = '/auth/login';
    }

    if (!response.ok) {
        // Handle error status
        const errorData = await response.json();
        throw new Error(errorData.message || 'Unknown error');
    }

    // If response is OK, proceed with the data
    return await response.json()
};

const fetchAllInvestmentTypes = async () => {
    let response;
    try {
        response = await fetch(process.env.REACT_APP_BACKEND_URL+'/api/investments/types', {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        });
    } catch (error) {
        // Handle network errors or errors during parsing
        console.error(error.message);
    }

    if (response.status === 403) {
        // Redirect to the login page
        window.location.href = '/auth/login';
    }

    if (!response.ok) {
        // Handle error status
        const errorData = await response.json();
        throw new Error(errorData.message || 'Unknown error');
    }

    // If response is OK, proceed with the data
    return await response.json()
};

const createInvestment = async (data) => {
    let response;
    try {
        response = await fetch(process.env.REACT_APP_BACKEND_URL+'/api/investments', {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });
    } catch (error) {
        // Handle network errors or errors during parsing
        console.error(error.message);
    }

    if (response.status === 403) {
        // Redirect to the login page
        window.location.href = '/auth/login';
    }

    if (!response.ok) {
        // Handle error status
        const errorData = await response.json();
        throw new Error(errorData.message || 'Unknown error');
    }

    // If response is OK, proceed with the data
    return await response.json()
};

const updateInvestment = async (id, data) => {
    let response;
    try {
        response = await fetch(process.env.REACT_APP_BACKEND_URL+'/api/investments/'+id, {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });
    } catch (error) {
        // Handle network errors or errors during parsing
        console.error(error.message);
    }

    if (response.status === 403) {
        // Redirect to the login page
        window.location.href = '/auth/login';
    }

    if (!response.ok) {
        // Handle error status
        const errorData = await response.json();
        throw new Error(errorData.message || 'Unknown error');
    }

    // If response is OK, proceed with the data
    return await response.json()
};

const deleteInvestment = async (id) => {
    let response;
    try {
        response = await fetch(process.env.REACT_APP_BACKEND_URL+'/api/investments/'+id, {
            method: 'DELETE',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        });
    } catch (error) {
        // Handle network errors or errors during parsing
        console.error(error.message);
    }

    if (response.status === 403) {
        // Redirect to the login page
        window.location.href = '/auth/login';
    }

    if (!response.ok) {
        // Handle error status
        const errorData = await response.json();
        throw new Error(errorData.message || 'Unknown error');
    }

    // If response is OK, proceed with the data
    return await response.json()
};

const fetchInvestmentsOverall = async (user) => {
    let response;
    try {
        response = await fetch(process.env.REACT_APP_BACKEND_URL+'/api/investments/'+user+'/overall', {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        });
    } catch (error) {
        // Handle network errors or errors during parsing
        console.error(error.message);
    }

    if (response.status === 403) {
        // Redirect to the login page
        window.location.href = '/auth/login';
    }

    if (!response.ok) {
        // Handle error status
        const errorData = await response.json();
        throw new Error(errorData.message || 'Unknown error');
    }

    // If response is OK, proceed with the data
    return await response.json()
};

const investmentAPI = {
    fetchInvestments,
    fetchAllInvestmentUsers,
    fetchAllInvestmentTypes,
    createInvestment,
    updateInvestment,
    deleteInvestment,
    fetchInvestmentsOverall
};

export default investmentAPI;