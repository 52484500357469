const fetchLatestPrice = async () => {
    let response;
    try {
        response = await fetch(process.env.REACT_APP_BACKEND_URL+'/api/stocks/latest', {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        });
    } catch (error) {
        // Handle network errors or errors during parsing
        console.error(error.message);
    }

    if (response.status === 403) {
        // Redirect to the login page
        window.location.href = '/auth/login';
    }

    if (!response.ok) {
        // Handle error status
        const errorData = await response.json();
        throw new Error(errorData.message || 'Unknown error');
    }

    // If response is OK, proceed with the data
    return await response.json()
};

const stocksAPI = {
    fetchLatestPrice
};

export default stocksAPI;